
@media (max-width:850px) {
    .double-page{
        display: flex;
        flex-direction: column;
    }

    .break-hing{
        display: none;
    }
    .single-page{
        width: 100%;
    }
}

@media (max-width:600px) {

    .home .leftpage-content{
        width: 450px !important;
    }

    
}

@media (max-width:450px) {

    .home .leftpage-content{
        width: 300px !important;
    }
    
}

.discription{
    padding: 8px 5px 9px 10px;
}

.discription h3, .discription a, .discription h5{
    color:white;
    margin: 0 0 5px 0;
}

.discription p, .discription ul{
    color: rgb(51 195 221);
    margin-bottom: 20px;
}

.display-work{
    margin-bottom: 10px;
}

.single-page{
    height: 100vh;
    overflow-y: auto;
}

.about .right{
    flex-wrap: nowrap !important;
    justify-content: flex-start;
}

@media (max-width:920px) {

    .work .left{
        justify-content: flex-start;
        align-items: center;
    }
}

.leftpage-content p{
    margin-bottom: 15px;
}

.next-page {
    background: #3f3d56;
}

.address p, .address a{
    color: #11a99c;
    font-size: 1rem;
}

.break-hing{
    position: absolute;
    top: 0;
    left: 48.1%;
    width: 3.5vw;
    height: 100vh;
    z-index: 100;
}

.header-nav .right{
    background: #3f3d56;
    border-radius: 50%;
}

.resume_button{
    background: #07c5ae;
    border: none;
    color: white;
    padding: 15px;
    border-radius: 5px;
    font-size: 1.2rem;
    position: relative;
    bottom: 69px;
    text-decoration: none;
    width: 180px;
    /* left: calc(50% - 90px); */
    left: -5%;
}
.backpage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.back-cover{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}